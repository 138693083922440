<template>
  <div class="mainHeader">
    <!--  :style="$route.name != 'studyway' ? 'box-shadow:0 2px 10px 0  rgba(0,0,0,.11);' : ''" -->
    <div class="nav">
      <!-- 学习之旅页面展示白色图标 -->
      <!-- <img v-if="$route.name == 'studyway'" src="../../assets/images/student/backhome.png" alt="" width="50" @click="$router.replace({path:'/home'})"> -->
      <!-- 其他页面展示蓝色图标 -->
      <img src="../../assets/images/student/bluebackhome.png" alt="" width="50" @click="$router.replace({path:'/home'})">

      <i class="el-icon-arrow-right" :style="$route.name == 'studyway' ?'color:#fff;' : 'color:#333;'"></i>

      <div class="text" v-for="(item,index) in header_data" :key="index" :style="$route.name != 'studyway' ? 'color:#333;' : 'color:#fff;'">
        <span class="span-text" @click="back(item,index)">{{item.title}}</span>
        <span class="span">-</span>
      </div>
    </div>
    <div class="Right">
      <!-- 学习之旅页面展示白色图标 -->
      <div class="main" v-if="$route.name == 'studyway'">
        <el-dropdown trigger="click" @command="returnLogin" :hide-on-click="false">
          <div class="el-dropdown-link">
            <svg-icon icon="icon7" v-if="fellow" style="position: relative;top: -15px;left: 15px"></svg-icon>
            <img src="../../assets/images/student/mine.png" alt="">
            <span>
              {{$store.state.userInfo.xm}} <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
          </div>
          <template>
            <el-dropdown-menu>
              <el-dropdown-item command="20">设置</el-dropdown-item>
              <el-dropdown-item command="10">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <!-- 其他页面展示蓝色图标 -->
      <div class="applcationmain" v-else>
        <el-dropdown trigger="click" @command="returnLogin" :hide-on-click="false">

          <div class="el-dropdown-link">
            <svg-icon icon="icon7" v-if="fellow" style="position: relative;top: -15px;left: 15px"></svg-icon>
            <img src="../../assets/images/student/bluemine.png" alt="">
            <span>
              {{$store.state.userInfo.xm}} <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
          </div>
          <template>
            <el-dropdown-menu>
              <el-dropdown-item command="20">设置</el-dropdown-item>
              <el-dropdown-item command="10">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="selectBb" v-if="$route.name == 'books'">
         <span class="bbxz">版本选择</span>
        <el-dropdown trigger="click" v-model="selected">
          <div class="selectXx">
            {{selected}}<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown" style="width:100px;margin-right:10px">
            <el-dropdown-item :disabled="data.mc === selected" v-for="data in list" :key="data.id" @click.native="selectFn(data.mc,data.id)">{{ data.mc }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <Exitpopup :exit_data="exit_data" />
  </div>
</template>

<script>
import { browserUrl } from '@/utils/index.js'
import {mapState} from "vuex";
export default {
  components: {
    Exitpopup: (resolve) => {
      require(['@/components/student/exitpopup.vue'], resolve)
    }
  },
  name: 'mainHeader',
  props: {
    header_data: {
      type: Array
    },
    bbid: {
      type: Number,
      default: 0
    },
    bbmc: {
      type: String,
      default: ''
    },
    dropList: {
      type: Array
    }
  },
  data() {
    return {
      list: [],
      bb: {},
      selected: '',
      browserUrl: browserUrl, // 退出登陆地址
      exit_data: { // 控制退出登录弹窗显示隐藏
        isShow: false
      }
    }
  },
  computed: {
    ...mapState(['fellow']),
  },
  created() {
    this.list = this.dropList
    this.$route.query.bbmc
    this.selected = this.$route.query.bbmc
  },
  methods: {

    selectFn(mc, id) {
      this.selected = mc
      this.$parent.bbid = id
      this.$parent.bbmc = mc
      this.$parent.getBooks(id)
    },
    // 退出登录
    returnLogin(command) {
      if (command == 10) {
        // 退出登录
        this.exit_data.isShow = true
      } else if (command == 20) {
        // 设置
        this.$router.replace({ path: '/setUpPage/information' })
      }
      // this.exit_data.isShow = true
    },
    back(val, index) {
      // 面包屑最后一个禁止点击
      if (index == this.header_data.length - 1) {
        return
      }
      this.$router.replace({ path: val.path })
    },
  },
}
</script>
<style lang="scss" scoped>
.el-dropdown-link{
  display: flex;
}
.mainHeader {
  height: 90px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1999;
}
.Right {
  position: absolute;
  top: 23px;
  right: 40px;
  //overflow: hidden;
  .selectBb {
    float: right;
    padding-right: 30px;
  }
}
.main {
  float: right;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
  line-height: 50px;
  animation-delay: 0.6s;
  letter-spacing: 2px;
  position: relative;
  img {
    display: block;
    width: 50px;
    height: 50px;
    float: left;
  }
  span {
    display: block;
    float: left;
    margin-left: 10px;
  }
}
.applcationmain {
  float: right;
  color: #333;
  font-size: 26px;
  cursor: pointer;
  line-height: 50px;
  animation-delay: 0.6s;
  letter-spacing: 2px;
  position: relative;
  img {
    display: block;
    width: 50px;
    height: 50px;
    float: left;
  }
  span {
    display: block;
    float: left;
    margin-left: 10px;
  }
}
.main {
  .el-dropdown {
    color: #fff;
    font-size: 24px;
  }
}
.applcationmain {
  .el-dropdown {
    color: #333;
    font-size: 24px;
  }
}
.nav {
  position: absolute;
  top: 23px;
  left: 40px;
  overflow: hidden;
  color: #fff;
  img {
    display: block;
    float: left;
    cursor: pointer;
  }
  i {
    float: left;
    font-size: 24px;
    line-height: 50px;
    padding: 0 15px;
  }
  .text {
    float: left;
    line-height: 50px;
    font-size: 24px;
    letter-spacing: 3px;
  }
}
.border {
  border-bottom: 1px solid red;
}
.text:last-child {
  .span {
    display: none;
  }
}
.span-text {
  cursor: pointer;
}
</style>
<style lang="scss">
.mainHeader {
  .el-icon--right {
    display: none;
  }
  .el-button--primary {
    background: #fff;
    border-color: #2ecbff;
    color: #333;
    box-shadow: 0px 0px 8px #2ecbff;
    border-radius: 30px;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
.bbxz{
  font-size: 14px;
  padding-right: 10px;
}
.selectXx {
  cursor: pointer;
  width: 120px;
  height: 40px;
  background-color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  border-radius: 30px;
  margin-top: 5px;
}
</style>
